<template>
    <div class="RoleList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="roleForm" ref="roleForm">
                    <el-form-item label="角色名称">
                        <el-input v-model="roleForm.roleName" placeholder="请输入角色名称查询"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="clearfix">
                    <el-button class="btn" @click="$router.push({ name: 'role-create' })"
                        icon="el-icon-circle-plus-outline">新增</el-button>
                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="roleList" border  style="width: 100%; font-size: 13px;"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="roleId" label="角色编号" align="center">
                </el-table-column>
                <el-table-column prop="roleName" label="角色名称" align="center"> </el-table-column>
                <el-table-column prop="level_text" label="级别" align="center">
                </el-table-column>
                <el-table-column prop="status_text" label="状态" align="center">
                </el-table-column>
                <el-table-column prop="createDateTime" label="创建时间" align="center" width="160">
                </el-table-column>
                <el-table-column prop="startDate" label="开始日期" align="center" width="100">
                </el-table-column>
                <el-table-column prop="endDate" label="结束日期" align="center" width="100">
                </el-table-column>

                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="修改" @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="text" size="mini" title="删除" @click="del(scope.row)"
                            class="delColor">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="roleForm.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="roleForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>


        </el-card>
    </div>
</template>

<script>
import { searchRoleData, delRole, getGrades } from '@/api/role'
export default {
    data() {
        return {
            roleList: [],
            roleForm: {
                roleName: '',
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
        }
    },
    mounted() {
        this.getGrade()
        this.searchRoleData()
    },
    methods: {
        handleSizeChange(val) {
            this.roleForm.pageSize = val;
            this.roleForm.pageNum = 1;
            this.searchRoleData();
        },
        handleCurrentChange(val) {
            this.roleForm.pageNum = val;
            this.searchRoleData();
        },

        //查询角色信息
        searchRoleData() {
            searchRoleData(this.roleForm).then(res => {
                if (res.code === '000000') {
                    this.roleList = res.data.list
                    this.total = res.data.total
                    this.getGrade()
                    this.roleList.forEach((item) => {
                        item.status === 1 ? (item.status_text = '启用') : (item.status_text = '停用')
                        this.gradeList.forEach((grade) => {
                            if (item.level + '' === grade.pKey) {
                                item.level_text = grade.pValue
                            }
                        })

                    })

                }
            })
        },
        //查询角色级别
        getGrade() {
            getGrades().then(res => {
                if (res.code === '000000') {
                    this.gradeList = res.data
                }
            })
        },
        handleEdit({ roleId }) {
            this.$router.push({
                name: 'role-edit',
                params: {
                    roleId
                }
            })
        },

        //删除
        del({roleId,roleName}) {
            this.$confirm('是否删除名称为“'+roleName+'”的角色？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delRole(roleId).then(res => {
                    if (res.code === '000000') {
                        this.$message.success('删除成功')
                        this.searchRoleData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })

        },
        //搜索
        handleSearch() {
            this.searchRoleData(this.roleForm);
        },
        //重置
        handleReset() {
            this.roleForm = {};
            this.searchRoleData(this.roleForm);
        },


    }

}
</script>


<style lang="scss" scoped>
.RoleList {

    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }

}
</style>